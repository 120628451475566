<template>
	<div class="app-container">
		<el-card class="filter-container" shadow="never">
			<div>
				<i class="el-icon-search"></i>
				<span>筛选搜索</span>
				<el-button style="float: right" @click="searchAdminList()" type="primary" size="small">查询结果</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" :model="listQuery" size="small" label-width="140px">
					<el-form-item label="输入角色名称："><el-input style="width: 203px" v-model="filter[0].val" placeholder="角色名称/关键字"></el-input></el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-card class="operate-container" shadow="never">
			<i class="el-icon-tickets"></i>
			<span>角色列表</span>
			<el-button class="btn-add" @click="addAdmin()" size="mini">创建角色</el-button>
		</el-card>
		<div class="table-container">
			<el-table ref="adminTable" :data="list" stripe style="width: 100%" @selection-change="" v-loading="listLoading" border>
				<el-table-column type="selection" width="60" align="center"></el-table-column>
				<!-- <el-table-column label="编号" align="center">
					<template slot-scope="scope">
						{{ scope.row.id }}
					</template>
				</el-table-column> -->

				<el-table-column label="角色名称" align="center">
					<template slot-scope="scope">
						{{ scope.row.roleName }}
					</template>
				</el-table-column>
				<el-table-column label="角色类型" align="center">
					<template slot-scope="scope">
						{{ scope.row.roleType }}
					</template>
				</el-table-column>
				<!-- <el-table-column label="群组" align="center" width="180">
					<template slot-scope="scope">
						{{ scope.row.groupCode }}
					</template>
				</el-table-column> -->
				<el-table-column label="状态" align="center">
					<template slot-scope="scope">
						{{scope.row.status == 0 ? '禁用' : '启用'}}
					</template>
				</el-table-column>
				<el-table-column label="添加时间" align="center">
					<template slot-scope="scope">
						{{ scope.row.createdTxStamp }}
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" fixed="right">
					<template slot-scope="scope">
						<el-button size="mini" v-loading.fullscreen.lock="fullscreenLoading" @click="addMenu(scope.row)">菜单配置</el-button>
						<!-- <el-button size="mini" @click="addAdmin(scope.row)">编辑</el-button> -->
						<el-button size="mini" type="danger" @click="handleDelete(scope.row)">删除</el-button>
						<el-switch v-model="scope.row.status" @change="switChange($event, scope.row)" active-color="#409eff" inactive-color="#ff4949" active-text="启用" inactive-text="禁用" :active-value="1" :inactive-value="0"></el-switch>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="40%">
			<el-form :model="dict" :rules="rules" ref="dictFrom" label-width="150px">
				<el-form-item label="角色名称：" prop="roleName"><el-input v-model="dict.roleName"></el-input></el-form-item>
				<el-form-item label="角色类型：" prop="roleType">
					<el-select v-model="dict.roleType" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
					    <el-option v-for="item in genreArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-form-item label="群组编码：" prop="groupCode"><el-input v-model="dict.groupCode" :disabled="isEdit"></el-input></el-form-item> -->
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="onSubmit('dictFrom')">确 定</el-button>
			</span>
		</el-dialog>
		
		<el-dialog :title="diaTitle" :visible.sync="dialogVisi" width="40%">
			<el-form :model="menu" :rules="rules" ref="menuFrom" label-width="150px">
				<el-form-item label="角色名称：">
				  <el-input v-model="menu.roleName" :disabled="isDisabled"></el-input>
				</el-form-item>
				<el-form-item label="关联权限：">
				   <el-tree
				    ref="tree"
				    :check-strictly="checkStrictly"
				    :data="menuList"
				    :props="defaultProps"
				    show-checkbox
				    node-key="id"
				    :default-checked-keys="checkedArr"
				    class="permission-tree"
				    @check-change="handleCheckChange"
				  >
				  </el-tree>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisi = false">取 消</el-button>
				<el-button type="primary" @click="submitMenu('menuFrom')">确 定</el-button>
			</span>
		</el-dialog>
		
	</div>
</template>
<script>
const defaultDict = {
	roleName: '',
	roleType: '',
	status: 1
};
const defaultMenu = {
	roleName: '',
	roleId: '',
	menuIds:''
};
export default {
	name: 'adminList',
	data() {
		return {
			fullscreenLoading: false,
			genreArr: [{
				value: 'admin',
				label: 'admin'
			}, {
				value: 'organiza',
				label: 'organiza'
			}],
			isEdit:false,
			dialogVisi:false,
			dialogVisible: false,
			dialogTitle: '',
			diaTitle:'',
			isDisabled:true,
			dict: Object.assign({}, defaultDict),
			menu:Object.assign({}, defaultMenu),
			listQuery: {
				entity: 'FaAdminSysRole',
				page: 1,
				pageSize: 20,
				isNoQueryRetailerId: 1
			},
			filter: [
				{
					col: 'roleName',
					type: 'like',
					val: ''
				},
				// {
				// 	col: 'groupCode',
				// 	type: 'like',
				// 	val: ''
				// }
			],
			list: [],
			menuList: [],
			checkedArr:[],
			listLoading: true,
			checkStrictly: true	,
			defaultProps: {
			  children: "childrens",
			  label: "name"
			},
			rules: {
				roleName: [
					{ required: true, message: '请输入角色名称', trigger: 'blur' },
					{
						min: 2,
						max: 140,
						message: '长度在 2 到 140 个字符',
						trigger: 'blur'
					}
				],
				roleType: [{ required: true, message: '请选择类型', trigger: 'blur' }]
				// groupCode: [{ required: true, message: '请选择群组编码', trigger: 'blur' }]
			}
		};
	},
	created() {
		this.getList();
	},
	methods: {
		searchAdminList(){
			this.getList();
		},
		handleCheckChange(data, checked, indeterminate){
			if(checked){
				this.checkedArr.push(data.id);
			}else{
				let arr = this.checkedArr;
				arr.forEach((item,index,arr) => {
				  if (item == data.id) {
				    arr.splice(index, 1)
				  }
				})
			}
		},
		getList() {
			this.listLoading = true;
			this.listQuery.filter = JSON.stringify(this.filter);
			this.$comjs.ajax.getAjax('/jqGrid/commonlist', this.listQuery, this, res => {
				this.listLoading = false;
				this.list = res.rows;
			});
		},
		handleDelete(row) {
			this.$confirm('是否要删除该角色', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				let data = {
					id: row.id,
					entity: 'FaAdminSysRole'
				};
				this.$comjs.ajax.postAjax('/jqGrid/commonDeleteById', data, this, res => {
					this.$message({
						message: '删除成功',
						type: 'success',
						duration: 1000
					});
					this.getList();
				});
			});
		},
		// 表格-是否启用
		switChange(val, row) {
			let data = {
				id: row.id,
				status: val,
				entity: 'FaAdminSysRole'
			}
			this.$comjs.ajax.postAjax('/jqGrid/changeStatus', data, this, res => {
				this.$message({
					message: res.message,
					type: 'success'
				});
				this.getList();
			});
		},
		submitMenu(formName){
			var that = this;
			let data={
				roleId:this.menu.roleId,
				menuIds:this.checkedArr.join()
			}
			this.$confirm('是否提交数据', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'	
			}).then(() => {
				that.$comjs.ajax.postAjax('/fa/admin/system/menu/saveRoleIdMenuIds', data, this, res => {
					this.dialogVisi = false;
					that.$refs[formName].resetFields();
					that.menu = Object.assign({}, defaultMenu);
					that.$message({
						message: '保存成功！',
						type: 'success',
						duration: 1000
					});
				});
			});
		},
		onSubmit(formName) {
			var that = this;
			this.$refs[formName].validate(valid => {
				if (valid) {
					let data = {
						entity: 'FaAdminSysRole',
						model: 'xuefa',
						dataObject: JSON.stringify(this.dict)
					};
					this.$confirm('是否提交数据', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'	
					}).then(() => {
						that.$comjs.ajax.postAjax('/jqGrid/commonDataSave', data, this, res => {
							this.dialogVisible = false;
							that.$refs[formName].resetFields();
							that.dict = Object.assign({}, defaultDict);
							that.$message({
								message: '保存成功！',
								type: 'success',
								duration: 1000
							});
							that.getList();
						});
					});
				}
			});
		},
		addMenu(row){
			this.diaTitle = '菜单配置';
			this.menu={
				roleName:row.roleName,
				roleId:row.id
			}
			this.getMenuList(row.id);
		},
		getMenuList(id) {
			this.fullscreenLoading = true;
			let data={
			  roleId:id	
			}
			this.$comjs.ajax.getAjax('/fa/admin/system/menu/getRoleIdSelectMenuList', data, this, res => {
				if (res.status == 200) {
					this.checkedArr = [];
					for (let i = 0; i < res.list.length; i++) {
						if(res.list[i].checked) this.checkedArr.push(res.list[i].id)
					}
				 //    this.checkedArr = res.list.map((item)=>{
					// 	return item.checked?item.id:'';
					// })
				    this.menuList =this.$comjs.treeDataTranslate(res.list, "id", "pid");
					this.dialogVisi = true;
					this.fullscreenLoading = false;
				  }
			});
		},
		addAdmin(row) {
			if (row) {
				this.dialogVisible = true;
				this.dialogTitle = '编辑角色';
				this.dict = {
					id: row.id,
					roleName: row.roleName,
					groupCode: row.groupCode
				};
				this.isEdit = true;
			} else {
				this.dialogVisible = true;
				this.isEdit = false;
				this.dialogTitle = '新增角色';
				this.dict = Object.assign({}, defaultDict);
			}
		}
	}
};
</script>
<style scoped>
.operate-container {
	margin-top: 20px;
}
.operate-container .btn-add {
	float: right;
}
</style>
